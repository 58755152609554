import React from "react"
import Layout from "../components/layout.js"
import { Link } from "gatsby"
import Head from "../components/head.js"
import "./404.scss"
import BackgroundSection from "../components/backgroundSection.js"

const NotFound = () => {
  return (
    <section className="notFound-pg">
      <Head title={"404 Page Not Found"} />
      <Layout>
        <BackgroundSection imgsrc="notFound404.jpg">
          <div className="notFound-pg__content">
            <h1>404</h1>
            <h2>Page Not Found</h2>
            <p>
              <Link to="/">back to Home page</Link>
            </p>
          </div>
        </BackgroundSection>
      </Layout>
    </section>
  )
}

export default NotFound
